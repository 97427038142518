import React, { useEffect, useState } from "react";
import { ImQuotesRight, ImQuotesLeft } from "react-icons/im";
import { IconContext } from "react-icons";

export default function QuoteCard({ quote, minView }) {
  return (
    <div className="idea-card quote-card">
      <div className="dflex">
        <IconContext.Provider
          value={{ color: "rgba(128, 75, 74, 0.3)", className: "quote-icon" }}
        >
          <ImQuotesLeft />
        </IconContext.Provider>
      </div>
      <div className="dflex flex-1">{quote}</div>
      <div className="dflex flex-end">
        <IconContext.Provider
          value={{ color: "rgba(128, 75, 74, 0.3)", className: "quote-icon" }}
        >
          <ImQuotesRight />
        </IconContext.Provider>
      </div>
    </div>
  );
}
