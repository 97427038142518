import React, { useEffect, useRef } from "react";
import CardContent from "../components/CardContent";

export default function AboutMe({ minView }) {
  const myRef = useRef(null);
  const executeScroll = () => myRef?.current?.scrollIntoView();
  useEffect(() => {
    executeScroll();
  }, []);

  return (
    <div
      ref={myRef}
      style={{ width: minView ? "90vw" : "80vw" }}
      className="app-content-center"
    >
      <CardContent minView={minView} />
      <div
        style={{
          borderBottom: "1px solid rgba(220,220,220,1)",
          paddingBottom: "15px",
        }}
      >
        Former Senator Mike Morrell was born and raised in California. He was
        married here, raised his family here, and opened two corporations here,
        Provident Home Loans and Provident Realty, both incorporated in 1989.
        Mike and his wife, Joanie, have three adult children. Their daughter
        Kristen’s first priority is her family and she also teaches on American
        Exceptionalist at Arizona Christian University. Their oldest son, David,
        serves as legal counsel at the Department of Justice in Washington D.C.
        Matthew, their youngest, works as an operations manager for a commercial
        development company in Arlington, Virginia.
      </div>

      <h2>Policy Committies</h2>
      <div
        style={{
          borderBottom: "1px solid rgba(220,220,220,1)",
          paddingBottom: "15px",
        }}
      >
        Senator Morrell, ret. was first elected to serve the 40th Assembly in
        2010 and then the 23rd Senate District in 2014 until 2020. He served on
        a number of committees including:{" "}
        <div style={{ flexDirection: "column" }}>
          <div className="dflex">• Housing (Vice Chair)</div>
          <div className="dflex">• Banking & Financial Institutions</div>
          <div className="dflex">• Budget & Fiscal Review</div>
          <div className="dflex">
            • Energy, Utilities, & Communications (Vice Chair)
          </div>
          <div className="dflex">
            • Jobs, Economic Development, & the Economy
          </div>
          <div className="dflex">• Public Safety</div>
        </div>
      </div>

      <h2>Acknowledgements</h2>
      <div
        style={{
          paddingBottom: "15px",
        }}
      >
        Senator Morrell regularly received high marks from the California
        Chamber of Commerce and the Howard Jarvis Taxpayers Association. He has
        been named Legislator of the Year by the Military Officers Association
        of America and the California Young Republican Federation. Additionally,
        Senator Morrell has been recognized as the AmPac Connecting Faith &
        Business Community Leader of the Year, a National Federation of
        Independent Business (NFIB) and has received awards from the California
        Senior Legislature. He has consistently lent his time and talents to the
        community.
        <p />
        He has served on the Board of Directors for the Building Industry
        Association and the Board of the Rancho Cucamonga YMCA, as well as the
        Advisory Board for the Gary Anderson School of Business at UC Riverside.
        He was a key member of the Inland Empire Economic Partnership and the
        Legislative Committee for the Citrus Valley Association of Realtors.
        Senator Morrell co-founded the Inland Empire Prayer Breakfast and was on
        the Advisory Board to the Pacific Justice Institute. He is a former
        Hillsdale College member of the Parents Board and also chaired the
        Claremont Institute President’s Club.
      </div>
    </div>
  );
}
