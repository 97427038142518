import React, { useRef, useEffect } from "react";
import QuoteCard from "../components/QuoteCard";

export default function Sales({ minView }) {
  const myRef = useRef(null);
  const executeScroll = () => myRef?.current?.scrollIntoView();
  useEffect(() => {
    executeScroll();
  }, []);

  const quotes = [
    <QuoteCard
      minView={minView}
      quote="Give me your hardworking, trainable sales persons and I will help put together a solid game plan designed to help them achieve the results your firm is looking for."
    />,
    <QuoteCard
      minView={minView}
      quote={
        <div>
          The book of Proverbs instructs us that "diligent hands make you rich".
          Yet many in sales mistakenly believe that magic touch or charismatic
          personality is required for success. Nothing can ever take the place
          of perseverance.
        </div>
      }
    />,
    <QuoteCard
      minView={minView}
      quote=" There is always a way to reach goals. I will help you rid your sales staff of excuses"
    />,
  ];

  function getRandomQuote() {
    return quotes[0];
  }

  return (
    <div
      ref={myRef}
      style={{ width: minView ? "90vw" : "80vw" }}
      className="app-content-center"
    >
      {getRandomQuote()}
      <div className="m-top-15">
        <div
          style={{
            borderBottom: "1px solid rgba(220,220,220,1)",
            paddingBottom: "15px",
            lineHeight: "1.6em",
          }}
        >
          <h2>Experience That Counts</h2>
          In 1982, interest rates hit an all time high of <b>19%</b>. The real
          estate market in California hit a record low - the worst since the
          depression.
          <p />
          The company I was employed with was in trouble and my wife was
          pregnant with our first child. Therefore, I had to stay, if only for
          the health insurance coverage. Recalling those ancient principles of
          hard work, I had a challenge ahead.
          <p />
          Seven months later, our daughter Kristen was born and I had set a new
          company record sales. Since then I've worked with, managed and have
          spoken with many in sales and know that success is reached through
          hard work and a solid game plan.
          <p />
          We will make you better and can help you accomplish this with minimum
          effort.
        </div>
        <p />
      </div>
      <div
        style={{
          borderBottom: "1px solid rgba(220,220,220,1)",
          paddingBottom: "15px",
          lineHeight: "1.6em",
        }}
      >
        <h2>Niche Marketing</h2>
        We often hear that for a business to succeed, one must find a "niche
        market" or another term - "Think outside the box". Yet many can't define
        accurately what these terms mean.
        <p />
        It is our job to help develop your "niche". In addition, time and again
        many businesses miss opportunities by not keeping in touch with past
        clients. Research has shown it costs substantially less to market to
        past clients and obtain another sale than advertising to capture a new
        client.
        <p />
        <div style={{ flexDirection: "column" }}>
          <div className="dflex">
            • Tapping into the wealth of past and existing clients - Recapture
            letter to past clients
          </div>
          <div className="dflex">• Mass marketing on a personal level</div>
          <div className="dflex">
            • What is a niche? What does it mean to think outside the box?{" "}
          </div>
        </div>
      </div>
    </div>
  );
}
