import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { GrClose } from "react-icons/gr";

import "./components.css";
import Footer from "./Footer";

const tabs = [
  { title: "Public Speaking", index: 0, urlLink: "" },
  {
    title: "Sales & Marketing Training",
    index: 1,
    urlLink: "sales",
  },
  { title: "About", index: 3, urlLink: "about" },
];

const Menu = ({}) => {
  const history = useNavigate();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(0);
  const menuRef = useRef(null);

  function handleClickOutside(event) {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  return (
    <div>
      <div className="menu-clickable" onClick={() => setOpen(!open)}>
        <GiHamburgerMenu />
      </div>
      <div
        ref={menuRef}
        className={`menu-container ${open ? "menu-open" : "menu-close"}`}
      >
        <div className="menu-close-icon">
          <div className="menu-clickable" onClick={() => setOpen(false)}>
            <GrClose />
          </div>
        </div>
        <div>
          {tabs.map((x) => (
            <div
              key={x.index}
              onClick={() => {
                setSelected(x.index);
                history(x.urlLink);
                setOpen(false);
              }}
              className={`header-tab-item menu-item ${
                selected === x.index ? "tab-selected" : ""
              }`}
            >
              {x.title}
            </div>
          ))}
        </div>
        <div className="menu-footer">
          <Footer minView={true} />
        </div>
      </div>
    </div>
  );
};

export default function Tabs({ minView, height }) {
  const history = useNavigate();
  const [selected, setSelected] = useState(0);

  return (
    <div className={`header ${height > 80 ? "header-sm" : ""}`}>
      <div className="cross-container">MIKE MORRELL</div>

      {minView
        ? null
        : tabs.map((x) => (
            <div
              key={x.index}
              onClick={() => {
                setSelected(x.index);
                history(x.urlLink);
              }}
              className={`header-tab-item ${
                selected === x.index ? "tab-selected" : ""
              }`}
            >
              {x.title}
            </div>
          ))}

      <div className="header-grow">
        {minView ? (
          <Menu />
        ) : (
          <div className="book-now-button">
            <a style={{ color: "white !important" }} href="tel:9099381138">
              Contact
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
