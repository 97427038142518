import React from "react";
import "./SocialBanner.css";

import { IconContext } from "react-icons";

import {
  AiOutlineCopyrightCircle,
  AiOutlineMail,
  AiOutlinePhone,
  AiFillLinkedin,
  AiFillFacebook,
  AiFillInstagram,
  AiFillYoutube,
} from "react-icons/ai";

const footerIconStyle = {
  marginRight: "15px",
};

export default function SocialBanner({ minView }) {
  return (
    <div className="social-banner">
      <IconContext.Provider
        value={{ color: "white", className:  "social-banner-wrap" }}
      >
        <a href="https://www.facebook.com/MikeMorrellGOP" target="_blank">
          <AiFillFacebook />
        </a>
        <a
          href="https://www.instagram.com/mikemorrellgop/?hl=en"
          target="_blank"
        >
          <AiFillLinkedin />
        </a>
        <a
          href="https://www.instagram.com/mikemorrellgop/?hl=en"
          target="_blank"
        >
          <AiFillInstagram />
        </a>
        <a
          href="https://www.youtube.com/channel/UC_kImtOpWGLpFPWSQFgBhtg"
          target="_blank"
        >
          <AiFillYoutube />
        </a>
      </IconContext.Provider>
    </div>
  );
}
