import React from "react";
import QuoteCard from "../components/QuoteCard";

const topics = [
  "Purpose",
  "Political Life",
  "Personal Life",
  "Finance",
  "Overcoming a Life of Neutrality",
];

export default function Speaking({ minView }) {
  return (
    <div
      style={{ width: minView ? "90vw" : "80vw" }}
      className="app-content-center"
    >
      <div style={{ marginBottom: "25px" }}>
        <QuoteCard
          minView={minView}
          quote="Allow myself the opportunity to help you accomplish the results you are looking to achieve"
        />
      </div>
      <div
        style={{
          borderBottom: "1px solid rgba(220,220,220,1)",
          paddingBottom: "25px",
        }}
      >
        I’ve been a CEO of two corporations, I’ve owned a property management
        company, and I was fortunate enough to be elected to the California
        State Senate. My whole life goes back to the day I discovered a set of
        principles in regard to living a purpose driven life which I
        continuously practice to this day.
        <p />
        I worked on making our people better, our customers better, and
        resulted in people coming to our office to do business with us as well
        as recruiting top tier talent who wanted to be mentored by us. This was
        a major part of discovering my purpose in life.
        <p />
        Let me help you work through finding your purpose in life and how to
        incorporate that into your life or business. Life is too short to be
        born, work, and die without living a life well spent with purpose.
      </div>

      <h2>Purpose Driven</h2>
      <div
        style={{
          borderBottom: "1px solid rgba(220,220,220,1)",
          paddingBottom: "25px",
        }}
      >
        The results of not having a pathway in life to which you know your place
        before God can cause a loss of passion in life and emptiness as well as
        a loss of convictions; leading to perhaps one of the worst things I
        believe - a life of neutrality.
        <p />
        The great statesmen, like Churchill and Lincoln, certainly did not lead a
        life of neutrality, they led a life full of purpose. I want to help people 
        begin to understand what their purpose in life is so they can contribute
        to their own life in a much deeper way beyond themselves and
        integrate their purpose into daily life and business.
      </div>

      <div
        style={{ flexDirection: minView ? "column" : "row", marginTop: "35px" }}
        className="speaking-image-container"
      >
        <div className="image-container">
          <img
            className={minView ? "speaking-image" : "speaking-image-large"}
            src={require("../images/mikeSpeaking.png")}
            alt="icon"
          />
        </div>
        <div
          style={{
            paddingLeft: minView ? "0px" : "25px",
            marginTop: minView ? "25px" : "",
          }}
          className="image-text"
        >
          <div className="image-text-title">Key Speaking Topics</div>
          <div className="key-speaking-topics">
            {topics.map((x) => (
              <div className="key-topic">{x}</div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
