import React from "react";
import { IconContext } from "react-icons";

import {
  AiOutlineCopyrightCircle,
  AiOutlineMail,
  AiOutlinePhone,
  AiFillLinkedin,
  AiFillFacebook,
  AiFillInstagram,
  AiFillYoutube,
} from "react-icons/ai";

const footerIconStyle = {
  marginRight: "15px",
};

export default function Footer({ minView }) {
  return (
    <div className="footer border-top">
      <div
        className={minView ? "footer-info-min" : "footer-info"}
      >
        <div className={`footer-contact ${minView ? 'footer-contact-min' : ''}`}>
          <AiOutlineCopyrightCircle style={footerIconStyle} /> Mike Morrell,
          2023
        </div>
        <div className={`footer-contact ${minView ? 'footer-contact-min' : ''}`}>
          <AiOutlineMail style={footerIconStyle} />
          <a className="footer-mailto" href="mailto:prhl@aol.com">
            prhl@aol.com
          </a>
        </div>
        <div className={`footer-contact ${minView ? 'footer-contact-min' : ''}`}>
          <AiOutlinePhone style={footerIconStyle} />{" "}
          <a className="footer-mailto" href="tel:9099381138">
            (909) - 938 - 1138
          </a>
        </div>
      </div>
      <div className="footer-icons">
        <IconContext.Provider
          value={{ color: "white", className: "icon-wrapper" }} 
        >
          <a href="https://www.facebook.com/MikeMorrellGOP" target="_blank">
            <AiFillFacebook />
          </a>
          <a
            href="https://www.instagram.com/mikemorrellgop/?hl=en"
            target="_blank"
          >
            <AiFillLinkedin />
          </a>
          <a
            href="https://www.instagram.com/mikemorrellgop/?hl=en"
            target="_blank"
          >
            <AiFillInstagram />
          </a>
          <a
            href="https://www.youtube.com/channel/UC_kImtOpWGLpFPWSQFgBhtg"
            target="_blank"
          >
            <AiFillYoutube />
          </a>
        </IconContext.Provider>
      </div>
    </div>
  );
}
