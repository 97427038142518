import React from "react";

const ideas = [
  { title: 25, content: "Years of small business ownernship" },
  {
    title: 20,
    content: "Years Public Speaking",
  },
  { title: 10, content: "Years in the California Legisture" },
];

export default function CardContent() {
  return (
    <div style={{ marginBottom: "25px" }} className="content-container">
      {ideas.map((x) => (
        <div key={x.title} className="idea-card">
          <div className="ic-title">{x.title}</div>
          <div className="ic-body">{x.content}</div>
        </div>
      ))}
    </div>
  );
}
