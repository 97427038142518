import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import "./App.css";
import AboutMe from "./Views/AboutMe";
import Tabs from "./components/Tabs";
import Footer from "./components/Footer";
import Sales from "./Views/Sales";
import Speaking from "./Views/Speaking";
import ContentTop from "./components/ContentTop";
import SocialBanner from "./components/SocialBanner";

const minFlip = 853;

export default function App() {
  const [height, setHeight] = useState(0);
  const [minView, setMinView] = useState(window.innerWidth < minFlip);

  useEffect(() => {
    function handleResize() {
      setMinView(window.innerWidth < minFlip);
    }

    function listenScrollEvent(e) {
      setHeight(window.scrollY);
    }
    window.addEventListener("scroll", listenScrollEvent);
    window.addEventListener("resize", handleResize);
  });

  const windowWidth = window.innerWidth;
  let picWidth = 400;
  if (windowWidth < picWidth) {
    picWidth = windowWidth - 50;
  }

  return (
    <div className="App">
      <Tabs height={height} minView={minView} />

      <ContentTop minView={minView} height={height} picWidth={picWidth} />

      <SocialBanner minView={minView} />

      <div className="app-content">
        <Routes>
          <Route path="/" element={<Speaking minView={minView} />} />
          <Route path="about" element={<AboutMe minView={minView} />} />
          <Route path="sales" element={<Sales minView={minView} />} />
          <Route path="purpose" element={<Sales minView={minView} />} />
        </Routes>
      </div>

      <div className="copy-contact">
        <div className="dflex">
          I would appreciate the opportunity to advise and counsel you on
          discovering your purpose.
        </div>
        <div className="dflex">
          <span style={{ marginRight: "8px" }}>Please contact me at:</span>
          <a className="footer-mailto" href="tel:9099381138">
            (909) - 938 - 1138
          </a>
        </div>
      </div>
      <Footer minView={minView} />
    </div>
  );
}
