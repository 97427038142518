import React from "react";
import "./ContentTop.css";

const profile = require("../../images/mmProfile.png");

export default function ContentTop({ minView, height, picWidth }) {
  return (
    <div className={`content-top ${minView ? "content-top-min" : "dflex"}`}>
      <div className="flex-column">
        {/* <div className="m-text">Mike Morrell,</div> */}
        <div className="l-text">Mike Morrell</div>
        <div className="text-blob">
          I am a presenter, keynote speaker, father, husband, businessman, and
          former California State Senator. I would sincerely appreciate the
          opportunity to work with you and help walk you through discovering
          your mission and purpose while here.
        </div>
      </div>
      <div>
        <div className="profile-card">
          <img
            style={{ width: `${picWidth}px` }}
            className="profile-image"
            src={profile}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
